
import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxPaging,
  DxEditing,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxButton,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxList from 'devextreme-vue/list';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import router from '../router';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import robotoNormal from '@/assets/fonts/Roboto-Thin-normal.js'
import robotoRegular from '@/assets/fonts/Roboto-Thin-regular.js'

import CompanyService from "../services/company-service";
import ClientService from "../services/client-service";
import WorkOrderService from "../services/workorder-service";
import TypeMaintenanceService from "../services/type-maintenance-service";
import CompanyClientService from "../services/company-client-service";
import ClientContactService from "../services/client-contact-service";
import SendMailService from "../services/send-mail-service";
import ServiceReportService from "../services/service-report-service";

import CryptoJS from "crypto-js";

function encryptId(id, key) {
  const ciphertext = CryptoJS.AES.encrypt(id.toString(), key).toString();
  return encodeURIComponent(ciphertext);
}

export default {
  components: {
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxColumnChooser,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxList,
    DxLoadPanel,
    DxEditing,
  },
  data() {
    const now = new Date();
    return {
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      popupTitle: "Envío de Correo",
      popupVisible: false,
      workOrderId: null,
      popupWorkOrder: null,
      mode: 'select',
      isVisible: false,
      message: '',
      type: 'info',
      client: null,
      company: null,
      usuarioId: null,
      gridRefName: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceServiceReport: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceWorkOrders: null,
      dataSourceMaintenanceTypes: null,
      dataSourceEmails: null,
      selectedItemKeys: [],
      IsDisabled: false,
      loadingVisible: false,
      position: { of: '#gridPanelReporte' },
      showIndicator: true,
      shading: true,
      showPane: true,
      hideOnOutsideClick: false,
      textLoading: "",
      visibleRol: false,
      encr: null,
      idrol: null,
      visiblecol: false,
    };
  },
  created() {
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.companyClientService = new CompanyClientService();
    this.workOrderService = new WorkOrderService();
    this.clientContactService = new ClientContactService();
    this.sendMailService = new SendMailService();
    this.serviceReportService = new ServiceReportService();
  },
  mounted() {
    this.encr = process.env.VUE_APP_ENCRYPT_KEY;
    this.usuarioId = localStorage.getItem("user-data");
    const datarol = JSON.parse(localStorage.getItem("user"));
    this.idrol = datarol.id_rol;
    //1 Administrador 2 Gerente 3 Supervisor
    if (this.idrol == 1 || this.idrol == 2 || this.idrol == 3) {
      this.visiblecol = true;
    }

    this.companyService.getEmpresas().then((data) => {
      this.dataSourceCompanies = data;
      this.company = data[0];
      this.companyClientService.getClientesAsignadosPorEmpresaCombo(this.company.id_empresa).then(data => this.dataSourceClients = data);
    });
    this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes = data);
  },
  methods: {
    showReport(e) {
      router.push({ name: 'service-report', params: { id_orden_trabajo: encryptId(e.row.data.id_orden_trabajo, this.encr) } });
    },
    sendEmail(e) {
      console.log('showreport');
      console.log(e);
    },
    getClientCompany() {
      this.companyClientService
        .getClientesAsignadosPorEmpresaCombo(this.company.id_empresa)
        .then((data) => {
          this.client = null;
          this.dataSourceClients = data;
        });
      this.getOrdenTrabajoReporteServicio();
    },
    getOrdenTrabajoReporteServicio() {
      var id_usuario = localStorage.getItem("user-data");
      if (
        this.company != null &&
        this.startDate != null &&
        this.endDate != null
      ) {
        const params = {
          id_empresa: this.company.id_empresa,
          id_cliente: this.client != null ? this.client.id_cliente : null,
          fecha_inicio: this.startDate,
          fecha_fin: this.endDate,
          id_usuario: id_usuario,
        };
        this.workOrderService.getOrdenTrabajoReporteServicio(params).then(data => this.dataSourceWorkOrders = data);
      }
    },

    onClick() {
      console.log('Click');
    },

    showEmails(e) {
      this.selectedItemKeys = [];
      var cliente = "";
      for (var i = 0; i < this.dataSourceClients.length; i++) {
        if (this.dataSourceClients[i].id_cliente == e.row.data.id_cliente) {
          cliente = this.dataSourceClients[i].cliente
        }
      }
      this.showPopup(cliente, e.row.data.id_orden_trabajo);

    },
    createpdf(e) {
      //var imgUrl = require("../assets/11.png"); //Dev Local 
      this.loadingVisible = true;
      this.textLoading = "Creando reporte";
      this.serviceReportService
        .getReporteServiciosWeb(e.row.data.id_orden_trabajo)
        .then((dataReporte) => {
          const doc = new jsPDF('portrait', 'mm', 'a4', true);
          doc.addFileToVFS('Roboto-normal1.ttf', robotoNormal)
          doc.addFileToVFS('Roboto-regular.ttf', robotoRegular)
          doc.setFont("RobotoRegular");

          doc.setDrawColor("35B729");
          doc.setLineWidth(0);
          doc.setFillColor("35B729");
          doc.triangle(200, 0, 20, 0, 120, 10, 'FD');


          doc.setDrawColor("F80202");
          doc.setLineWidth(0);
          doc.setFillColor("F80202");
          doc.triangle(0, 0, 0, 3, 80, 10, 'FD');
          doc.triangle(140, 0, 0, 0, 80, 10, 'FD');

          doc.setDrawColor("004EFF");
          doc.setLineWidth(0);
          doc.setFillColor("004EFF");
          doc.triangle(120, 0, 20, 0, 60, 5, 'FD');

          var imageurl = dataReporte["orden_trabajo"]["logo_empresa"];

          doc.text("Reporte de Servicio", 155, 15, { align: "left" });
          var img = new Image;
          img.src = imageurl;
          //img.src = imgUrl; //Dev Local
          doc.addImage(img, 5, 5, 50, 20);
          doc.setFontSize(9);

          doc.setFillColor("E2F7DA");
          doc.rect(5, 30, 200, 28, 'F');

          doc.setFont("RobotoNormal");
          doc.text("Empresa: ", 8, 35, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["orden_trabajo"]["empresa"] ?? "", 22, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Cliente: ", 8, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["orden_trabajo"]["cliente"] ?? "", 20, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Atención: ", 8, 45, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["orden_trabajo"]["atencion"] ?? "", 22, 45, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Proyecto: ", 8, 50, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["proyecto"] ?? ""), 22, 50, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Responsable: ", 8, 55, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["responsable"] ?? ""), 28, 55, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Orden de Trabajo: ", 110, 35, { align: "left" });

          const otfolio = (dataReporte["orden_trabajo"].folio ?? "").toString() + (dataReporte["orden_trabajo"].folioorigen == null ? '' : ' (Relación con ' + dataReporte["orden_trabajo"].folioorigen + ')').toString();

          doc.setFont("RobotoRegular");
          doc.text(otfolio, 136, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Fecha de órden de trabajo: ", 110, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["fecha"] ?? ""), 148, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Tipo de Mantenimiento: ", 110, 45, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["tipo_mantenimiento"] ?? ""), 144, 45, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Clasificación: ", 110, 50, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["tipo_mayor_menor"] ?? ""), 130, 50, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Referencia: ", 110, 55, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text((dataReporte["orden_trabajo"]["referencia"] ?? ""), 126, 55, { align: "left" });

          let value = 65;

          if (dataReporte.orden_trabajo["origen_orden_trabajo_clave"] == "RF") {

            doc.setFillColor("EBF1F2");
            doc.rect(5, value + 5, 200, 24, 'F');
            doc.setFontSize(12);

            doc.text("Reporte de Falla: " + (dataReporte.origen_orden_trabajo[0].folio ?? ""), 5, value, { align: "left" });
            value += 10;
            doc.setFontSize(9);

            doc.setFont("RobotoNormal");
            doc.text("Fecha del reporte: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text((dataReporte.origen_orden_trabajo[0].fecha ?? ""), 34, value, { align: "left" });

            value += 5;

            doc.setFont("RobotoNormal");
            doc.text("Título: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text((dataReporte.origen_orden_trabajo[0].titulo ?? ""), 18, value, { align: "left" });

            value += 5;
            doc.setFont("RobotoNormal");
            doc.text("Descripción: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text((dataReporte.origen_orden_trabajo[0].descripcion ?? ""), 26, value, { align: "left" });

            value += 5;
            doc.setFont("RobotoNormal");
            doc.text("Reportado por: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text((dataReporte.origen_orden_trabajo[0].usuario_reporta ?? ""), 30, value, { align: "left" });
            value += 10;

            var reportefallas = dataReporte.reportefallaspreview.filter(ele => ele.id_tipo_archivo === 6);
            if (reportefallas.length > 0) {
              var countfalla = Number((reportefallas.length / 4).toFixed(2));

              let fallaposicion = value;
              doc.setFontSize(9);
              doc.setFont("RobotoNormal");
              let textToSizeDesRF = 0;
              reportefallas.forEach((reporte, index) => {
                var img = new Image;
                img.src = (reporte["url"] ?? "");
                // img.src = imgUrl; //Dev Local

                let desimg = this.truncate((reporte["descripcion"] ?? ""), 24, '...');
                let usuario_reporta = this.truncate((reporte["usuario_reporta"] ?? ""), 24, '...');
                let fecha_reg = this.truncate((reporte["fecha_reg"] ?? ""), 24, '...');
                index += 1;

                if ((index % 4) == 1) {
                  textToSizeDesRF = 0;
                  doc.addImage(img, 8, fallaposicion, 40, 40);
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 8, fallaposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  textToSizeDesRF = textsize;
                  doc.text(usuario_reporta, 8, fallaposicion + (50 + textsize), { align: "left" });
                  doc.text(fecha_reg, 8, fallaposicion + (55 + textsize), { align: "left" });
                }

                if ((index % 4) == 2) {
                  doc.addImage(img, 60, fallaposicion, 40, 40);
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 60, fallaposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  textToSizeDesRF = textsize;
                  doc.text(usuario_reporta, 60, fallaposicion + (50 + textsize), { align: "left" });
                  doc.text(fecha_reg, 60, fallaposicion + (55 + textsize), { align: "left" });
                  if (textsize > textToSizeDesRF) {
                    textToSizeDesRF = textsize;
                  }
                }

                if ((index % 4) == 3) {
                  doc.addImage(img, 110, fallaposicion, 40, 40);
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 110, fallaposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  textToSizeDesRF = textsize;
                  doc.text(usuario_reporta, 110, fallaposicion + (50 + textsize), { align: "left" });
                  doc.text(fecha_reg, 110, fallaposicion + (55 + textsize), { align: "left" });
                  if (textsize > textToSizeDesRF) {
                    textToSizeDesRF = textsize;
                  }
                }

                if ((index % 4) == 0) {
                  doc.addImage(img, 160, fallaposicion, 40, 40);
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 160, fallaposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  textToSizeDesRF = textsize;
                  doc.text(usuario_reporta, 160, fallaposicion + (50 + textsize), { align: "left" });
                  doc.text(fecha_reg, 160, fallaposicion + (55 + textsize), { align: "left" });
                  if (textsize > textToSizeDesRF) {
                    textToSizeDesRF = textsize;
                  }
                  fallaposicion += (58 + textToSizeDesRF);
                }
              });
              value += ((countfalla) > 1 ? fallaposicion - 35 : (65));
            }
          }

          dataReporte.equipos.forEach((element) => {
            doc.setFontSize(12);
            doc.setFont("RobotoRegular");
            doc.text((element.info_equipo[0]["alias"] ?? element.info_equipo[0]["nombre_equipo"]), 8, value, { align: "left" });

            value += 6;
            doc.setFontSize(9);
            var yaccion = 8;
            if ((element.info_equipo[0]["iniciales"] ?? "").length > 0) {
              doc.text((element.info_equipo[0]["iniciales"] ?? "") + " ", 8, value, { align: "left" });
              yaccion = (element.info_equipo[0]["iniciales"] ?? "").length + 10;
            }
            if ((element.info_equipo[0]["accion_texto"] ?? "").length > 0) {
              doc.setFont("RobotoNormal");
              doc.text("  • Acción: ", yaccion, value, { align: "left" });
              yaccion += 14;
              doc.setFont("RobotoRegular");
              doc.text(" " + (element.info_equipo[0]["accion_texto"] ?? "") + " ", yaccion, value, { align: "left" });
              yaccion += (element.info_equipo[0]["accion_texto"] ?? "").length + 6;
            }
            if ((element.info_equipo[0]["comentario_mtto"] ?? "").length > 0) {
              let comentariomtto = (element.info_equipo[0]["comentario_mtto"] ?? "");
              let splitTextcomentariomtto = doc.splitTextToSize(comentariomtto, 170);
              doc.setFont("RobotoNormal");
              doc.text("  • Comentarios: ", yaccion, value, { align: "left" });
              yaccion += 22;
              doc.setFont("RobotoRegular");
              doc.text(splitTextcomentariomtto, yaccion, value, { align: "left" });
              value += (splitTextcomentariomtto.length * 3.1);
            }
            value += 2;
            doc.setFontSize(9);

            doc.setFillColor("EBF1F2");
            doc.rect(5, value, 200, element.dataSourceEquipmentFields1.length * 13, 'F');

            let textToSizeFields1 = 0;
            value += 5;
            let posicionFields1 = value;
            element.dataSourceEquipmentFields1.forEach((detalle1, index1) => {

              let name1 = (detalle1["name"] ?? "");
              let value1 = (detalle1["value"] ?? "");

              if ((index1 % 3) == 1) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 8, posicionFields1, { align: "left" });
                let position = name1.length + (6 + 8);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields1 = textsize;
              }
              if ((index1 % 3) == 2) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 80, posicionFields1, { align: "left" });
                let position = name1.length + (6 + 80);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields1 = textsize;
                if (textsize > textToSizeFields1) {
                  textToSizeFields1 = textsize;
                }
              }
              if ((index1 % 3) == 0) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 150, posicionFields1, { align: "left" });
                let position = name1.length + (6 + 150);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 40);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields1 = textsize;
                if (textsize > textToSizeFields1) {
                  textToSizeFields1 = textsize;
                }
              }
            });
            value += textToSizeFields1 + 4;
            let posicionFields2 = value;
            let textToSizeFields2 = 0;
            element.dataSourceEquipmentFields2.forEach((detalle2, index2) => {
              let name1 = (detalle2["name"] ?? "");
              let value1 = (detalle2["value"] ?? "");

              if ((index2 % 3) == 1) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 8, posicionFields2, { align: "left" });
                let position = name1.length + (6 + 8);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields2 = textsize;
              }
              if ((index2 % 3) == 2) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 80, posicionFields2, { align: "left" });
                let position = name1.length + (6 + 80);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields2 = textsize;
                if (textsize > textToSizeFields2) {
                  textToSizeFields2 = textsize;
                }
              }
              if ((index2 % 3) == 0) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 150, posicionFields2, { align: "left" });
                let position = name1.length + (6 + 150);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 40);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields2 = textsize;
                if (textsize > textToSizeFields2) {
                  textToSizeFields2 = textsize;
                }
              }
            });
            value += textToSizeFields2 + 5;
            let posicionFields3 = value;
            let textToSizeFields3 = 0;
            element.dataSourceEquipmentFields3.forEach((detalle3, index3) => {
              let name1 = (detalle3["name"] ?? "");
              let value1 = (detalle3["value"] ?? "");

              if ((index3 % 3) == 1) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 8, posicionFields3, { align: "left" });
                let position = name1.length + (8 + 8);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields3 = textsize;
              }
              if ((index3 % 3) == 2) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 80, posicionFields3, { align: "left" });
                let position = name1.length + (6 + 80);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields3 = textsize;
                if (textsize > textToSizeFields3) {
                  textToSizeFields3 = textsize;
                }
              }
              if ((index3 % 3) == 0) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 150, posicionFields3, { align: "left" });
                let position = name1.length + (6 + 150);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 40);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields3 = textsize;
                if (textsize > textToSizeFields3) {
                  textToSizeFields3 = textsize;
                }
              }
            });
            value += textToSizeFields3 + 5;
            let posicionFields4 = value;
            let textToSizeFields4 = 0;
            element.dataSourceEquipmentFields4.forEach((detalle4, index4) => {
              let name1 = (detalle4["name"] ?? "");
              let value1 = (detalle4["value"] ?? "");

              if ((index4 % 3) == 1) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 8, posicionFields4, { align: "left" });
                let position = name1.length + (4 + 8);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields4 = textsize;
              }
              if ((index4 % 3) == 2) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 80, posicionFields4, { align: "left" });
                let position = name1.length + (6 + 80);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 55);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields4 = textsize;
                if (textsize > textToSizeFields4) {
                  textToSizeFields4 = textsize;
                }
              }
              if ((index4 % 3) == 0) {
                doc.setFont("RobotoNormal");
                doc.text(name1 + ": ", 150, posicionFields4, { align: "left" });
                let position = name1.length + (6 + 150);
                doc.setFont("RobotoRegular");
                let splitText = doc.splitTextToSize(value1, 40);
                doc.text(splitText, position, value, { align: "left" });
                let textsize = (splitText.length * 3.1);
                textToSizeFields4 = textsize;
                if (textsize > textToSizeFields4) {
                  textToSizeFields4 = textsize;
                }
              }
            });
            value += textToSizeFields4 + 5;

            value += 5;
            doc.setFontSize(9);

            if (element.dataSourceSpecialFields1.length > 0 || element.dataSourceSpecialFields2.length > 0 || element.dataSourceSpecialFields3.length > 0 ||
              element.dataSourceSpecialFields4.length > 0) {
              value += 5;

              if (value >= 250) {
                doc.addPage();
                value = 15; // Restart height position
              }

              doc.text("Características especiales", 8, value, { align: "left" });
              value += 5;
              if (value >= 250) {
                doc.addPage();
                value = 15; // Restart height position
              }

              var columnsEspeciales = [
                { title: "Campo", dataKey: "campo" },
                { title: "Valor", dataKey: "valor" },
              ];

              let especiales = [];


              element.dataSourceSpecialFields1.forEach((special1) => {
                let name1 = (special1["name"] ?? "");
                let value1 = (special1["value"] ?? "");
                let data = {
                  campo: name1,
                  valor: value1,
                }
                especiales.push(data);
              });
              element.dataSourceSpecialFields2.forEach((special2) => {
                let name1 = (special2["name"] ?? "");
                let value1 = (special2["value"] ?? "");
                let data = {
                  campo: name1,
                  valor: value1,
                }
                especiales.push(data);
              });
              element.dataSourceSpecialFields3.forEach((special3) => {
                let name1 = (special3["name"] ?? "");
                let value1 = (special3["value"] ?? "");
                let data = {
                  campo: name1,
                  valor: value1,
                }
                especiales.push(data);
              });
              element.dataSourceSpecialFields4.forEach((special4) => {
                let name1 = (special4["name"] ?? "");
                let value1 = (special4["value"] ?? "");
                let data = {
                  campo: name1,
                  valor: value1,
                }
                especiales.push(data);
              });
              let finalespecial = value;
              let coordenadaespecial = 0

              doc.autoTable(columnsEspeciales, especiales, {
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                finalY: finalespecial,
                startY: (finalespecial),
                styles: {
                  fontSize: 9,
                  font: 'RobotoNormal'
                },
                margin: {
                  left: 8,
                  right: 8
                },
                headerStyles: {
                  font: 'RobotoRegular'
                }
              });

              if (doc.autoTable.previous) {
                coordenadaespecial = Number(doc.autoTable.previous.finalY.toFixed());
              }
              value = + (coordenadaespecial + 8);

              value += 5;
            }

            doc.text("Tareas y Mediciones", 8, value, { align: "left" });

            var columnsTareaMedicion = [
              { title: "Medición", dataKey: "medicion" },
              { title: "Resultado", dataKey: "resultado" },
              { title: "Acción", dataKey: "accion" },
              { title: "Observaciones", dataKey: "observaciones" },
              { title: "Técnico", dataKey: "usuario" },
            ];
            value += 5;
            let hash = {};
            var array = element.tareasmedicion.filter(o => hash[o.tarea] ? false : hash[o.tarea] = true);
            value += 5;
            array.forEach((tarea, indextarea) => {

              if (value >= 250) {
                doc.addPage();
                value = 15; // Restart height position
              }
              var tareasmedicion = element.tareasmedicion.filter((ele) => ele.tarea === tarea.tarea);

              doc.text(tarea.tarea, 8, value, { align: "left" });
              value += 5;
              let coordenada = 0
              let final = 0;

              if (indextarea > 0) {
                if (doc.autoTable.previous) {
                  final = value;
                  coordenada = Number(doc.autoTable.previous.finalY.toFixed());
                } else {
                  coordenada = 10
                  final = value;
                }
              } else {
                coordenada = value;
                final = value;
              }

              doc.autoTable(columnsTareaMedicion, tareasmedicion, {
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                finalY: final,
                startY: (final),
                styles: {
                  fontSize: 9,
                  font: 'RobotoNormal'
                },
                margin: {
                  left: 8,
                  right: 8
                },
                headerStyles: {
                  font: 'RobotoRegular'
                }
              });
              if (doc.autoTable.previous) {
                coordenada = Number(doc.autoTable.previous.finalY.toFixed());
              }
              value = + (coordenada + 8);
            });

            var evidenciasimg = element.evidenciaspreview.filter(ele => ele.id_tipo_archivo === 6);

            if (evidenciasimg.length > 0) {
              value += 5;
              doc.text("Evidencias", 8, value, { align: "left" });
              value += 5;
              if (value >= 240) {
                doc.addPage();
                value = 15; // Restart height position
              }

              let imgposicion = value;
              let textToSizeDes = 0;
              evidenciasimg.forEach((imgEvidencia, index) => {
                var img = new Image;
                img.src = (imgEvidencia["archivo"] ?? "");
                // img.src = imgUrl; //Dev Local

                var imgAntes = new Image;
                var imgCotizacion = new Image;

                if (imgEvidencia.antes_mtto) {
                  imgAntes.src = require("../assets/antes_mtto.png");
                } else {
                  imgAntes.src = require("../assets/despues_mtto.png");
                }

                imgCotizacion.src = require("../assets/cotizacion.png");

                doc.setFont("RobotoNormal");

                index += 1;
                if (imgposicion >= 240) {
                  doc.addPage();
                  imgposicion = 15; // Restart height position
                }

                if ((index % 4) == 1) {
                  textToSizeDes = 0;
                  doc.addImage(img, 8, imgposicion, 40, 40);
                  let desimg = ((imgEvidencia["descripcion"] ?? ""));
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 8, imgposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  textToSizeDes = textsize;
                  doc.addImage(imgAntes, 8, imgposicion + (46 + textsize));
                  if (imgEvidencia.cotizacion) {
                    doc.addImage(imgCotizacion, 8, imgposicion + (51 + textsize));
                  }
                }

                if ((index % 4) == 2) {
                  doc.addImage(img, 60, imgposicion, 40, 40);
                  let desimg = ((imgEvidencia["descripcion"] ?? ""));
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 60, imgposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  doc.addImage(imgAntes, 60, imgposicion + (46 + textsize));
                  if (imgEvidencia.cotizacion) {
                    doc.addImage(imgCotizacion, 60, imgposicion + (51 + textsize));
                  }
                  if (textsize > textToSizeDes) {
                    textToSizeDes = textsize;
                  }
                }

                if ((index % 4) == 3) {
                  doc.addImage(img, 110, imgposicion, 40, 40);
                  let desimg = ((imgEvidencia["descripcion"] ?? ""));
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 110, imgposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  doc.addImage(imgAntes, 110, imgposicion + (46 + textsize));
                  if (imgEvidencia.cotizacion) {
                    doc.addImage(imgCotizacion, 110, imgposicion + (51 + textsize));
                  }
                  if (textsize > textToSizeDes) {
                    textToSizeDes = textsize;
                  }
                }

                if ((index % 4) == 0) {
                  doc.addImage(img, 160, imgposicion, 40, 40);
                  let desimg = ((imgEvidencia["descripcion"] ?? ""));
                  let splitText = doc.splitTextToSize(desimg, 45);
                  doc.text(splitText, 160, imgposicion + 45, { align: "left" });
                  let textsize = (splitText.length * 3.1);
                  doc.addImage(imgAntes, 160, imgposicion + (46 + textsize));
                  if (imgEvidencia.cotizacion) {
                    doc.addImage(imgCotizacion, 160, imgposicion + (51 + textsize));
                  }
                  if (textsize > textToSizeDes) {
                    textToSizeDes = textsize;
                  }
                  imgposicion += (58 + textToSizeDes);
                }

              });
              value = imgposicion + 62 + textToSizeDes;
            }
            if (value >= 240) {
              doc.addPage();
              value = 15; // Restart height position
            }
          });

          if ((dataReporte["orden_trabajo"]["comentario"] ?? "").length > 0) {
            value += 5;
            if (value >= 240) {
              doc.addPage();
              value = 15; // Restart height position
            }
            doc.setFontSize(14);
            doc.setFont("RobotoRegular");
            doc.text("Comentarios de la orden de trabajo", 8, value, { align: "left" });
            value += 5;
            doc.setFontSize(9);
            doc.setFont("RobotoNormal");
            var comentario = doc.splitTextToSize((dataReporte["orden_trabajo"]["comentario"] ?? ""), 180);
            doc.text(comentario, 8, value, { align: "left" });
            let textsizecomentario = (comentario.length * 3.1);
            value += textsizecomentario;
            value += 15;
            if (value >= 240) {
              doc.addPage();
              value = 15; // Restart height position
            }
          }
          if ((dataReporte["orden_trabajo"]["firma"] ?? "").length > 0) {
            doc.setFontSize(14);
            doc.setFont("RobotoRegular");
            doc.text("Firmas", 8, value, { align: "left" });
            value += 5;
            if (value >= 240) {
              doc.addPage();
              value = 15; // Restart height position
            }
            var imgfirmaurl = (dataReporte["orden_trabajo"]["firma"] ?? "");
            var imgfirma = new Image;
            imgfirma.src = imgfirmaurl;
            // imgfirma.src = imgUrl; //Dev Local
            doc.addImage(imgfirma, 8, value, 40, 40);
            value += 5;
          }

          var imglogoapi = require("../assets/logo_apiclix_425.png"); //Dev Local
          var imgfooter = new Image;
          imgfooter.src = imglogoapi;

          const pageCount = doc.internal.getNumberOfPages();


          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.addImage(imgfooter, 8, pageHeight - 10, 15, 4);
            doc.setFontSize(14);
            doc.textWithLink('              ', 8, pageHeight - 6, { url: 'https://apiclix.com' });
            const footer = `Página ${i} de ${pageCount}`;
            doc.setFontSize(9);
            doc.setFont("RobotoNormal");
            doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
            doc.textWithLink('apicontrol.mx |', 164, pageHeight - 5, { url: 'https://apicontrol.mx' });
            doc.textWithLink('apiclix.com', 185, pageHeight - 5, { url: 'https://apiclix.com' });
          }

          var formData = new FormData();
          formData.append('file', doc.output("blob"), e.row.data.id_orden_trabajo);
          formData.append('id_orden_trabajo', e.row.data.id_orden_trabajo);

          this.serviceReportService
            .uploadfile(formData).then((data) => {
              if (data) {
                this.getOrdenTrabajoReporteServicio();
                this.loadingVisible = false;
              } else {
                this.loadingVisible = false;
              }

            });

          // this.loadingVisible = false;

          // Dev
          // window.open(
          //   URL.createObjectURL(doc.output("blob")),
          //   "_blank",
          //   "scrollbars=yes,location=yes"
          // );
          // setTimeout(() => {
          //   window.URL.revokeObjectURL(doc.output("bloburl"));
          // }, 100);

        });
    },
    showPopup(popupWorkOrder, workOrderId) {
      this.loading = true;
      this.workOrderId = workOrderId;
      this.popupWorkOrder = popupWorkOrder;
      this.popupVisible = true;
      this.refresh();
    },
    async refresh() {
      await this.clientContactService.getCorreosPorOrdenTrabajo(this.workOrderId).then((data) => {
        this.dataSourceEmails = data;
        this.loading = false;
      });
    },
    closeClick() {
      this.popupVisible = false;
    },
    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },
    sendMail() {
      var id_usuario = localStorage.getItem('user-data');
      var clienteContacto = [];
      var responsableOT = [];
      for (let index = 0; index < this.selectedItemKeys.length; index++) {
        const id = this.selectedItemKeys[index];
        var resultado = this.dataSourceEmails.filter((element) => element.id_cliente_contacto === id);
        resultado.forEach(element2 => {
          if (element2.tipo == 1) {
            clienteContacto.push(element2.id_cliente_contacto);
          }
          if (element2.tipo == 2) {
            responsableOT.push(element2.id_cliente_contacto);
          }
        });
      }

      let cliente_contacto = clienteContacto.filter((item, index) => {
        return clienteContacto.indexOf(item) === index;
      });

      let orden_trabajo_responsable = responsableOT.filter((item, index) => {
        return responsableOT.indexOf(item) === index;
      })

      var dataJson = {
        "id_orden_trabajo": this.workOrderId,
        "cliente_contacto": cliente_contacto,
        "orden_trabajo_responsable": orden_trabajo_responsable,
        "id_usuario": id_usuario
      };
      this.sendMailService.sendMailServiceReport(dataJson).then(data => {
        if (typeof (data.error) === "undefined") {
          this.popupVisible = false;
          this.getOrdenTrabajoReporteServicio();
          this.type = 'success';
          this.message = data;
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    isIconVisibleSend(e) {
      const usuario = JSON.parse(localStorage.getItem("user"));
      if (usuario.id_rol == 5) {
        return false;
      }
      return e.row.data.pdf;
    },
    isIconVisibleFile(e) {
      const usuario = JSON.parse(localStorage.getItem("user"));
      if (usuario.id_rol == 5) {
        return false;
      }
      return !e.row.data.pdf;
    },
    savedevent(e) {
      var changesdata = e.changes[0].data;
      this.workOrderService.updotbillcollect(changesdata).then(data => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = "Se ha actualizado el registro";
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    }
  },
};
