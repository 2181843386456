import axios from "axios";

export default class WorkOrderEquipmentEvidenceService {
  getEvidenciasComboPorOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoevidencias/combos/ordentrabajoequipos/${ordentrabajoequipo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEvidenciasPorOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoevidencias/ordentrabajoequipos/${ordentrabajoequipo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoEvidencias() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajoevidencias`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoEvidencia(ordentrabajoevidencia) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoevidencias/${ordentrabajoevidencia.id_orden_trabajo_evidencia}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateOrdenTrabajoEvidencia(ordentrabajoevidencia) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajoevidencias`;
    return axios.put(url, ordentrabajoevidencia).then((res) => res.data.data);
  }

  createOrdenTrabajoEvidencia(ordentrabajoevidencia) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajoevidencias`;
    return axios.post(url, ordentrabajoevidencia).then((res) => res.data.data);
  }

  deleteOrdenTrabajoEvidencia(ordentrabajoevidencia) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajoevidencias/eliminar`;
    return axios
      .delete(url, { data: ordentrabajoevidencia })
      .then((res) => res.data.data);
  }
}
