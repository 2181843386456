import axios from "axios";

export default class ClientService {
  getClientes() {
    const url = process.env.VUE_APP_APIURL + `clientes`;
    return axios.get(url).then((res) => res.data.data);
  }

  getCliente(cliente) {
    const url = process.env.VUE_APP_APIURL + `/clientes/${cliente.id_cliente}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getClienteComboPorUsuario() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `clientes/usuario`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getClienteComboPorUsuarioCombo() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `clientes/usuariocombo`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  updateCliente(cliente) {
    const url = process.env.VUE_APP_APIURL + `/clientes/${cliente.id_cliente}`;
    return axios.put(url, cliente).then((res) => res.data.data);
  }

  createCliente(cliente) {
    const url = process.env.VUE_APP_APIURL + `/clientes`;
    return axios.post(url, cliente).then((res) => res.data.data);
  }

  deleteCliente(cliente) {
    const url = process.env.VUE_APP_APIURL + `/clientes/${cliente.id_cliente}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
