
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import StatusQuoteService from '../services/status-quote-service';
import StatusPhysicalInventoryService from '../services/status-physical-inventory-service';
import StatusPurchaseOrderService from '../services/status-po-service';
import StatusWorkOrderService from '../services/status-wo-service';
import StatusWorkOrderEquipmentService from '../services/status-wo-equipment-service';
import StatusMaintenancePlanService from '../services/status-mp-service';
import StatusBugReportService from '../services/status-bug-report-service';
import StatusRequisitionService from '../services/status-requisition-service';
import StatusTaskService from '../services/status-task-service';
import StatusActivityService from '../services/status-activity-service';
import StatusChecklistService from '../services/status-checklist-service';
import StatusWarrantyService from '../services/status-warranty-service';

import { estatus } from '../data/data.js';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxSelectBox
  },
  props: {
    typeId: {
      type: Number,
      default: null,
    },
  },
  data() {

    return {
      dataSourceStatus: null,
      dataSourceStatusCombo: estatus,
      statusSelect: null,
      idEstatus: null,
      grupo: {},
      events: [],
      clave: false,
      color: false,      
      isVisible: false,
      message: '',
      type: 'success',      
      statusQuoteService: null,
      statusPhysicalInventoryService: null,
      statusPurchaseOrderService: null,
      statusWorkOrderService: null,
      statusWorkOrderEquipmentService: null, 
      statusMaintenancePlanService: null,
      statusBugReportService: null,
      statusRequisitionService: null,
      statusTaskService: null,
      statusActivityService: null,
      statusChecklistService: null,
      statusWarrantyService: null,
    };
  },
  created() {
    this.statusQuoteService = new StatusQuoteService();
    this.statusPhysicalInventoryService = new StatusPhysicalInventoryService();
    this.statusPurchaseOrderService = new StatusPurchaseOrderService();
    this.statusWorkOrderService = new StatusWorkOrderService();
    this.statusWorkOrderEquipmentService = new StatusWorkOrderEquipmentService();
    this.statusBugReportService = new StatusBugReportService();
    this.statusMaintenancePlanService = new StatusMaintenancePlanService();
    this.statusRequisitionService = new StatusRequisitionService();
    this.statusTaskService = new StatusTaskService();
    this.statusActivityService = new StatusActivityService();
    this.statusChecklistService = new StatusChecklistService();
    this.statusWarrantyService = new StatusWarrantyService();
  },
  methods: {
    valueChanged(e) {
        const newType = e.value;
        console.log(newType.idEstatus);
        switch (newType.idEstatus) {
          case 1:
            this.dataSourceStatus = [];
            this.idEstatus = 'id_estatus_cotizacion';
            this.clave = false;
            this.color = false;
            this.statusQuoteService.getEstatusCotizaciones().then(data => this.dataSourceStatus=data);
            break;
          case 2:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_inventario_fisico';  
            this.clave = false;
            this.color = false;
            this.statusPhysicalInventoryService.getEstatusInventarioFisicos().then(data => this.dataSourceStatus=data);
            break;
          case 3:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_orden_compra';
            this.clave = false;
            this.color = false;
            this.statusPurchaseOrderService.getEstatusOrdenCompras().then(data => this.dataSourceStatus=data);
            break;
          case 4:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_orden_trabajo';
            this.clave = false;
            this.color = true;
            this.statusWorkOrderService.getEstatusOrdenTrabajos().then(data => this.dataSourceStatus=data);
            break;
          case 5:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_orden_trabajo_equipo';
            this.clave = false;
            this.color = true;
            this.statusWorkOrderEquipmentService.getEstatusOrdenTrabajoEquipos().then(data => this.dataSourceStatus=data);
            break;
          case 6:
            this.dataSourceStatus = [];
            this.idEstatus = 'id_estatus_plan_mantenimiento';
            this.clave = true;
            this.color = false;
            this.statusMaintenancePlanService.getEstatusPlanMantenimientos().then(data => this.dataSourceStatus=data);
            break;
          case 7:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_reporte_falla';
            this.clave = false;
            this.color = true;
            this.statusBugReportService.getEstatusReporteFallas().then(data => this.dataSourceStatus=data);
            break;
          case 8:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_requisicion';
            this.clave = false;
            this.color = false;
            this.statusRequisitionService.getEstatusRequisiciones().then(data => this.dataSourceStatus=data);
            break;
          case 9:            
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_tarea';
            this.clave = true;
            this.color = false;
            this.statusTaskService.getEstatusTareas().then(data => this.dataSourceStatus=data);
            break;
          case 10:
            this.dataSourceStatus = [];        
            this.idEstatus = 'id_estatus_actividad';
            this.clave = false;
            this.color = false;
            this.statusActivityService.getEstatusActividades().then(data => this.dataSourceStatus=data);
            break;
          case 11:
            this.dataSourceStatus = [];
            this.idEstatus = 'id_estatus_lista_verificacion';
            this.clave = false;
            this.color = false;
            this.statusChecklistService.getEstatusListaVerificaciones().then(data => this.dataSourceStatus=data);
            break;
          case 12:
            this.dataSourceStatus = [];
            this.idEstatus = 'id_estatus_garantia';
            this.clave = false;
            this.color = false;
            this.statusWarrantyService.getEstatusGarantias().then(data => this.dataSourceStatus=data);
            break;
        }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editStatus(e) {    
      switch (this.statusSelect.idEstatus) {
          case 1:
            this.statusQuoteService.updateEstatusCotizacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.statusPhysicalInventoryService.updateEstatusInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.statusPurchaseOrderService.updateEstatusOrdenCompra(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.statusWorkOrderService.updateEstatusOrdenTrabajo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.statusWorkOrderEquipmentService.updateEstatusOrdenTrabajoEquipo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.statusMaintenancePlanService.updateEstatusPlanMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.statusBugReportService.updateEstatusReporteFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.statusRequisitionService.updateEstatusRequisicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.statusTaskService.updateEstatusTarea(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 10:            
            this.statusActivityService.updateEstatusActividad(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.statusChecklistService.updateEstatusListaVerificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 12:
            this.statusWarrantyService.updateEstatusGarantia(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
        }   			
		},
    deleteStatus(e) {
      switch (this.statusSelect.idEstatus) {
          case 1:
            this.statusQuoteService.deleteEstatusCotizacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.statusPhysicalInventoryService.deleteEstatusInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.statusPurchaseOrderService.deleteEstatusOrdenCompra(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.statusWorkOrderService.deleteEstatusOrdenTrabajo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.statusWorkOrderEquipmentService.deleteEstatusOrdenTrabajoEquipo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.statusMaintenancePlanService.deleteEstatusPlanMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.statusBugReportService.deleteEstatusReporteFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.statusRequisitionService.deleteEstatusRequisicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.statusTaskService.deleteEstatusTarea(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 10:            
            this.statusActivityService.deleteEstatusActividad(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.statusChecklistService.deleteEstatusListaVerificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 12:
            this.statusWarrantyService.deleteEstatusGarantia(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
      }
			
		},
    createStatus(e) {
      switch (this.statusSelect.idEstatus) {
        case 1:
            this.statusQuoteService.createEstatusCotizacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.statusPhysicalInventoryService.createEstatusInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.statusPurchaseOrderService.createEstatusOrdenCompra(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.statusWorkOrderService.createEstatusOrdenTrabajo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.statusWorkOrderEquipmentService.createEstatusOrdenTrabajoEquipo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.statusMaintenancePlanService.createEstatusPlanMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.statusBugReportService.createEstatusReporteFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.statusRequisitionService.createEstatusRequisicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.statusTaskService.createEstatusTarea(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 10:
            this.statusActivityService.createEstatusActividad(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.statusChecklistService.createEstatusListaVerificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 12:
            this.statusWarrantyService.createEstatusGarantia(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
      }
		},
  },
};
