import axios from 'axios';

export default class StatusWarrantyService {

	getEstatusGarantias() {			
        const url = process.env.VUE_APP_APIURL + `estatusgarantias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusGarantia(estatusgarantia) {
        const url = process.env.VUE_APP_APIURL + `/estatusgarantias/${estatusgarantia.id_estatus_garantia}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusGarantia(estatusgarantia){
        const url = process.env.VUE_APP_APIURL + `/estatusgarantias/${estatusgarantia.id_estatus_garantia}`;
		return axios.put(url,estatusgarantia).then(res=>res.data.data);
	}

	createEstatusGarantia(estatusgarantia){
        const url = process.env.VUE_APP_APIURL + `/estatusgarantias`;
		return axios.post(url,estatusgarantia).then(res=>res.data.data);
	}

	deleteEstatusGarantia(estatusgarantia){
        const url = process.env.VUE_APP_APIURL + `/estatusgarantias/${estatusgarantia.id_estatus_garantia}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}