import axios from 'axios';

export default class WorkOrderCostService {
    
    getOrdenTrabajoCostos(ordentrabajo) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajocostos/ordentrabajos/${ordentrabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoCosto(ordentrabajocosto) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajocostos/${ordentrabajocosto.id_orden_trabajo_costo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoCosto(ordentrabajocosto){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajocostos/${ordentrabajocosto.id_orden_trabajo_costo}`;
		return axios.put(url,ordentrabajocosto).then(res=>res.data.data);
	}

	createOrdenTrabajoCosto(ordentrabajocosto){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajocostos`;
		return axios.post(url,ordentrabajocosto).then(res=>res.data.data);
	}

	deleteOrdenTrabajoCosto(ordentrabajocosto){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajocostos/${ordentrabajocosto.id_orden_trabajo_costo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}