
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxScrolling, DxPager, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import EquipmentCategoryService from '../services/equipment-category-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxScrolling,
    DxPager,
    DxSearchPanel,
  },
  data() {
    return {      
      dataSourceEquipmentCategory: null,
      categoriaEquipo: {},
      events: [],
      equipmentCategoryService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
    };
  },
  created() {
    this.equipmentCategoryService = new EquipmentCategoryService();
  },
  mounted() {    
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
  },
  methods: {        
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipmentCategory(e) {
			this.equipmentCategoryService.updateEquipoCategoria(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al editar la categoría';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      });
		},
    deleteEquipmentCategory(e) {
			this.equipmentCategoryService.deleteEquipoCategoria(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar la categoría';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      });
		},
    createEquipmentCategory(e) {
			this.equipmentCategoryService.createEquipoCategoria(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear la categoría';
        this.isVisible = true;
        this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategory=data);
      });
		},
  },
};
