import axios from 'axios';

export default class WorkOrderEquipmentTaskService {
    
	getTareasComboPorOrdenTrabajoEquipo(ordentrabajoequipo) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajotareas/combos/ordentrabajoequipos/${ordentrabajoequipo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTareasPorOrdenTrabajoEquipo(ordentrabajoequipo) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajotareas/ordentrabajoequipos/${ordentrabajoequipo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoTareas() {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajotareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoTarea(ordentrabajotarea) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajotareas/${ordentrabajotarea.id_orden_trabajo_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoTarea(ordentrabajotarea){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajotareas/${ordentrabajotarea.id_orden_trabajo_tarea}`;
		return axios.put(url,ordentrabajotarea).then(res=>res.data.data);
	}

	createOrdenTrabajoTarea(ordentrabajotarea){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajotareas/web`;
		return axios.post(url,ordentrabajotarea).then(res=>res.data.data);
	}

	deleteOrdenTrabajoTarea(ordentrabajotarea){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajotareas/${ordentrabajotarea.id_orden_trabajo_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}