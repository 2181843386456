import axios from "axios";
 
export default class ClientUserService {

  getClientesDisponiblesPorUsuario(usuario) {
    const url =
      process.env.VUE_APP_APIURL + `clienteusuarios/disponibles/${usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getClientesAsignadosPorUsuario(usuario) {
    const url =
      process.env.VUE_APP_APIURL + `clienteusuarios/asignados/${usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getClienteUsuarios() {
    const url = process.env.VUE_APP_APIURL + `clienteusuarios`;
    return axios.get(url).then((res) => res.data.data);
  }

  getClienteUsuario(clienteusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `/clienteusuarios/${clienteusuario.id_cliente_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  createClienteUsuario(clienteusuario) {
    const url = process.env.VUE_APP_APIURL + `/clienteusuarios`;
    return axios.post(url, clienteusuario).then((res) => res.data.data);
  }

  updateClienteUsuario(clienteusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `clienteusuarios/${clienteusuario.id_cliente_usuario}`;
    return axios.put(url, clienteusuario).then((res) => res.data.data);
  }

  deleteClienteUsuario(clienteusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `/clienteusuarios/${clienteusuario.id_cliente_usuario}`;
    return axios.delete(url).then((res) => res.data.data);
  }

}
