
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import ProductService from '../services/product-service';
import GroupProductService from '../services/group-product-service';
import ProductSparePartService from '../services/product-spare-part-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
  },
  data() {
    return {
      items: null,
      dataSourceGroups: null,
      dataSourceProducts: null,
      dataSourceSparePartsAvailable: null,
      dataSourceSparePartsAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
      groups: null,
    };
  },
  created() {
    this.groupProductService = new GroupProductService();
    this.productService = new ProductService();
    this.productSparePartService = new ProductSparePartService();
  },
  mounted() {
    this.groupProductService.getGrupoProductos().then(data => this.dataSourceGroups = data);
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_articulo);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addSparePart() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
        this.productSparePartService.createProductoRefaccion({ "id_producto": this.items.id_producto, "id_articulo": this.selectedRowsData[this.i].id_articulo }).then(() => {

          if (this.i == this.selectedRowsData.length) {

            this.productSparePartService.getRefaccionesDisponiblesPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAvailable = data);
            this.productSparePartService.getRefaccionesAsignadasPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAssigned = data);
          }

        });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedGroup(e) {
      this.productService.getComboProductosPorGrupo(e.value.id_grupo_producto).then(data => this.dataSourceProducts = data);
      this.dataSourceSparePartsAvailable = null;
      this.dataSourceSparePartsAssigned = null;
    },
    valueChangedItem(e) {
      this.productSparePartService.getRefaccionesDisponiblesPorProducto(e.value.id_producto).then(data => this.dataSourceSparePartsAvailable = data);
      this.productSparePartService.getRefaccionesAsignadasPorProducto(e.value.id_producto).then(data => this.dataSourceSparePartsAssigned = data);
    },
    deleteSpareParts(e) {
      this.productSparePartService.deleteProductoRefaccion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.productSparePartService.getRefaccionesDisponiblesPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAvailable = data);
        this.productSparePartService.getRefaccionesAsignadasPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAssigned = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'No se puede eliminar la refacción';
        this.isVisible = true;
        this.productSparePartService.getRefaccionesDisponiblesPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAvailable = data);
        this.productSparePartService.getRefaccionesAsignadasPorProducto(this.items.id_producto).then(data => this.dataSourceSparePartsAssigned = data);
      });
    },
  },
};
