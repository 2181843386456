import axios from 'axios';

export default class StatusPhysicalInventoryService {

	getEstatusInventarioFisicos() {			
        const url = process.env.VUE_APP_APIURL + `estatusinventariofisicos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusInventarioFisico(estatusinventariofisico) {
        const url = process.env.VUE_APP_APIURL + `/estatusinventariofisicos/${estatusinventariofisico.id_estatus_inventario_fisico}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusInventarioFisico(estatusinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/estatusinventariofisicos/${estatusinventariofisico.id_estatus_inventario_fisico}`;
		return axios.put(url,estatusinventariofisico).then(res=>res.data.data);
	}

	createEstatusInventarioFisico(estatusinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/estatusinventariofisicos`;
		return axios.post(url,estatusinventariofisico).then(res=>res.data.data);
	}

	deleteEstatusInventarioFisico(estatusinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/estatusinventariofisicos/${estatusinventariofisico.id_estatus_inventario_fisico}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}