import axios from 'axios';

export default class WorkOrderOriginService {

	getOrigenOrdenTrabajos() {		
        const url = process.env.VUE_APP_APIURL + `origenordentrabajos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrigenOrdenTrabajo(origenordentrabajo) {
        const url = process.env.VUE_APP_APIURL + `/origenordentrabajos/${origenordentrabajo.id_origen_orden_trabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrigenOrdenTrabajo(origenordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/origenordentrabajos/${origenordentrabajo.id_origen_orden_trabajo}`;
		return axios.put(url,origenordentrabajo).then(res=>res.data.data);
	}

	createOrigenOrdenTrabajo(origenordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/origenordentrabajos`;
		return axios.post(url,origenordentrabajo).then(res=>res.data.data);
	}

	deleteOrigenOrdenTrabajo(origenordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/origenordentrabajos/${origenordentrabajo.id_origen_orden_trabajo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}