import axios from 'axios';

export default class WorkOrderEquipmentTaskMeasurementService {
    
	getMedicionesComboPorOrdenTrabajoTarea(ordentrabajotarea) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajomediciones/combos/ordentrabajotareas/${ordentrabajotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicionesPorOrdenTrabajoTarea(ordentrabajotarea) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajomediciones/ordentrabajotareas/${ordentrabajotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoMediciones() {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajomediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoMedicion(ordentrabajomedicion) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajomediciones/${ordentrabajomedicion.id_orden_trabajo_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoMedicion(ordentrabajomedicion){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajomediciones/web/${ordentrabajomedicion.id_orden_trabajo_medicion}`;
		return axios.put(url,ordentrabajomedicion).then(res=>res.data.data);
	}

	createOrdenTrabajoMedicion(ordentrabajomedicion){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajomediciones`;
		return axios.post(url,ordentrabajomedicion).then(res=>res.data.data);
	}

	deleteOrdenTrabajoMedicion(ordentrabajomedicion){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajomediciones/${ordentrabajomedicion.id_orden_trabajo_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}