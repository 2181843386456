import axios from 'axios';

export default class BrandService {

	getMarcas() {				
        const url = process.env.VUE_APP_APIURL + `marcas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMarca(marca) {
        const url = process.env.VUE_APP_APIURL + `/marcas/${marca.id_marca}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMarca(marca){
        const url = process.env.VUE_APP_APIURL + `/marcas/${marca.id_marca}`;
		return axios.put(url,marca).then(res=>res.data.data);
	}

	createMarca(marca){
        const url = process.env.VUE_APP_APIURL + `/marcas`;
		return axios.post(url,marca).then(res=>res.data.data);
	}

	deleteMarca(marca){
        const url = process.env.VUE_APP_APIURL + `/marcas/${marca.id_marca}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}