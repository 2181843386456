import axios from 'axios';

export default class LocationService {

	getLocalidades() {			
        const url = process.env.VUE_APP_APIURL + `localizaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getLocalidad(localizacion) {
        const url = process.env.VUE_APP_APIURL + `/localizaciones/${localizacion.id_localizacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateLocalidad(localizacion){
        const url = process.env.VUE_APP_APIURL + `/localizaciones/${localizacion.id_localizacion}`;
		return axios.put(url,localizacion).then(res=>res.data.data);
	}

	createLocalidad(localizacion){
        const url = process.env.VUE_APP_APIURL + `/localizaciones`;
		return axios.post(url,localizacion).then(res=>res.data.data);
	}

	deleteLocalidad(localizacion){
        const url = process.env.VUE_APP_APIURL + `/localizaciones/${localizacion.id_localizacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}