import axios from "axios";

export default class WorkOrderService {
  updateCambioEstatusOrdenTrabajo(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/estatus`;
    return axios.patch(url, ordentrabajo).then((res) => res.data.data);
  }

  getCalendarioOrdenTrabajosPorEmpresa(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `ordentrabajos/empresas/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoTodos() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajos/todos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoActivas() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajos/activas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoRealizadas() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajos/realizadas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajo(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajos/${ordentrabajo.id_orden_trabajo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenesTrabajoNuevas(empresa) {
    const url = process.env.VUE_APP_APIURL + `ordentrabajos/nuevas/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateAutorizaOrdenTrabajo(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/autorizaciones`;
    return axios.put(url, ordentrabajo).then((res) => res.data.data);
  }

  updateOrdenTrabajoCalendario(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajos/calendarios/${ordentrabajo.id_orden_trabajo}`;
    return axios.put(url, ordentrabajo).then((res) => res.data.data);
  }

  updateOrdenTrabajo(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajos/${ordentrabajo.id_orden_trabajo}`;
    return axios.put(url, ordentrabajo).then((res) => res.data.data);
  }

  createOrdenTrabajoDesdePlanMantenimiento(planmantenimiento) {
    const url =
      process.env.VUE_APP_APIURL + `/ordentrabajos/planmantenimientos`;
    return axios.post(url, planmantenimiento).then((res) => res.data.data);
  }

  createOrdenTrabajo(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos`;
    return axios.post(url, ordentrabajo).then((res) => res.data.data);
  }

  deleteOrdenTrabajo(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajos/${ordentrabajo.id_orden_trabajo}`;
    return axios
      .delete(url, { data: ordentrabajo })
      .then((res) => res.data.data);
  }

  // Crear orden de trabajo desde web
  createOrdenTrabajoWeb(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/web`;
    return axios.post(url, ordentrabajo).then((res) => res.data.data);
  }

  //Lista de orden de trabajo filtro Reporte servicio
  getWorkOrder(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/folios/web`;
    return axios
      .get(url, { params: ordentrabajo })
      .then((res) => res.data.data);
  }

  // Listado de ordenes de trabajo para generar reporte de servicio
  getOrdenTrabajoReporteServicio(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/reporteservicio`;
    return axios
      .get(url, { params: ordentrabajo })
      .then((res) => res.data.data);
  }

  getworkordersheet() {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/foliosot`;
    return axios.get(url).then((res) => res.data.data);
  }

  // Actualiza si aplica o no la garantia en una OT
  updateGarantia(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/garantias`;
    return axios.patch(url, ordentrabajo).then((res) => res.data.data);
  }

  getOrdenTrabajoLigaPorEmpresaCliente(empresa, cliente) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajos/foliosliga/${empresa}/${cliente}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updotbillcollect(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajos/facturaporcobrar`;
    return axios
      .patch(url, ordentrabajo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
