import axios from 'axios';

export default class UserCompanyService {

	
	getUsuarioEmpresa(usuarioempresa) {			
        const url = process.env.VUE_APP_APIURL + `usuarioempresas/usuarioempresa/${usuarioempresa}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getUsuarioEmpresas(usuario) {			
        const url = process.env.VUE_APP_APIURL + `usuarioempresas/${usuario}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	createUsuarioEmpresa(usuarioempresa){
        const url = process.env.VUE_APP_APIURL + `usuarioempresas`;
		return axios.post(url,usuarioempresa).then(res=>res.data.data);
	}

	deleteUsuarioEmpresa(usuario,empresa){
        const url = process.env.VUE_APP_APIURL + `usuarioempresas`;
		return axios.delete(url,{ params: { id_usuario: usuario, id_empresa: empresa} }).then(res=>res.data.data);
	}
}