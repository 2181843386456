import axios from 'axios';

export default class WorkOrderEquipmentTaskServiceService {
    
	getServiciosComboPorOrdenTrabajoTarea(ordentrabajotarea) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoservicios/combos/ordentrabajotareas/${ordentrabajotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getServiciosPorOrdenTrabajoTarea(ordentrabajotarea) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoservicios/ordentrabajotareas/${ordentrabajotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoServicios() {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoServicio(ordentrabajoservicio) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoservicios/${ordentrabajoservicio.id_orden_trabajo_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoServicio(ordentrabajoservicio){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoservicios/web/${ordentrabajoservicio.id_orden_trabajo_servicio}`;
		return axios.put(url,ordentrabajoservicio).then(res=>res.data.data);
	}

	createOrdenTrabajoServicio(ordentrabajoservicio){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoservicios/web`;
		return axios.post(url,ordentrabajoservicio).then(res=>res.data.data);
	}

	deleteOrdenTrabajoServicio(ordentrabajoservicio){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoservicios/${ordentrabajoservicio.id_orden_trabajo_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}