
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxSorting
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';

import ProjectService from '../services/project-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import TypeMaintenanceService from '../services/type-maintenance-service';
import PriorityService from '../services/priority-service';
import ConfigFrequencyService from '../services/config-frequency-service';
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import CompanyUserService from '../services/company-user-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxSorting
  },
  data() {
    return {
      equipos: null,
      expandedRowKeys: [1],
      dataSourceSuppliers: null,
      dataSourceClients: null,
      dataSourceProjects: null,
      dataSourcePriorities: null,
      dataSourceFrequencies: null,
      dataSourceMaintenanceTypes: null,
      dataSourceConfigPlanMaintenance: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Plan de Mantenimiento',
        showTitle: true,
        width: 800,
      },
      mode: 'select',
      dataSourceCompanies: null,
    };
  },
  created() {
    this.projectService = new ProjectService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.priorityService = new PriorityService();
    this.configFrequencyService = new ConfigFrequencyService();
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.companyUserService = new CompanyUserService();
  },
  mounted() {
    var id_usuario = localStorage.getItem("user-data");
    this.projectService.getProyectos().then(data => this.dataSourceProjects = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers = data);
    this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes = data);
    this.priorityService.getPrioridades().then(data => this.dataSourcePriorities = data);
    this.configFrequencyService.getConfigFrecuencias().then(data => this.dataSourceFrequencies = data);
    this.configPlanMaintenanceService.getConfigPlanMttos().then(data => this.dataSourceConfigPlanMaintenance = data);
    this.companyUserService.getEmpresasPorUsuario(id_usuario).then(data => this.dataSourceCompanies = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editItem(e) {
      this.configPlanMaintenanceService.updateConfigPlanMtto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        console.log('bandera');
      });
    },
    deleteItem(e) {
      this.configPlanMaintenanceService.deleteConfigPlanMtto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createItem(e) {
      this.configPlanMaintenanceService.createConfigPlanMtto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getArticuloPadres().then(data => this.dataSourceFatherItems=data);
    },
  },
};
