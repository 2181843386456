import axios from 'axios';

export default class StatusTaskService {

	getEstatusTareas() {			
        const url = process.env.VUE_APP_APIURL + `estatustareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusTarea(estatustarea) {
        const url = process.env.VUE_APP_APIURL + `/estatustareas/${estatustarea.id_estatus_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusTarea(estatustarea){
        const url = process.env.VUE_APP_APIURL + `/estatustareas/${estatustarea.id_estatus_tarea}`;
		return axios.put(url,estatustarea).then(res=>res.data.data);
	}

	createEstatusTarea(estatustarea){
        const url = process.env.VUE_APP_APIURL + `/estatustareas`;
		return axios.post(url,estatustarea).then(res=>res.data.data);
	}

	deleteEstatusTarea(estatustarea){
        const url = process.env.VUE_APP_APIURL + `/estatustareas/${estatustarea.id_estatus_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}