import axios from 'axios';

export default class SupplierService {

	getProveedores() {		
        const url = process.env.VUE_APP_APIURL + `proveedores`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProveedor(proveedor) {
        const url = process.env.VUE_APP_APIURL + `/proveedores/${proveedor.id_proveedor}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProveedor(proveedor){
        const url = process.env.VUE_APP_APIURL + `/proveedores/${proveedor.id_proveedor}`;
		return axios.put(url,proveedor).then(res=>res.data.data);
	}

	createProveedor(proveedor){
        const url = process.env.VUE_APP_APIURL + `/proveedores`;
		return axios.post(url,proveedor).then(res=>res.data.data);
	}

	deleteProveedor(proveedor){
        const url = process.env.VUE_APP_APIURL + `/proveedores/${proveedor.id_proveedor}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}