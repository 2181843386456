import axios from 'axios';

export default class WorkOrderEquipmentService {
    
    getUsuariosPorOrdenTrabajo(ordentrabajo) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoresponsables/usuarios/ordentrabajos/${ordentrabajo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoUsuarios() {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoresponsables`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoUsuario(ordentrabajousuario) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoresponsables/${ordentrabajousuario.id_orden_trabajo_responsable}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoUsuario(ordentrabajousuario){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoresponsables/${ordentrabajousuario.id_orden_trabajo_responsable}`;
		return axios.put(url,ordentrabajousuario).then(res=>res.data.data);
	}

	createOrdenTrabajoUsuario(ordentrabajousuario){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoresponsables`;
		return axios.post(url,ordentrabajousuario).then(res=>res.data.data);
	}

	deleteOrdenTrabajoUsuario(ordentrabajousuario){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoresponsables/${ordentrabajousuario.id_orden_trabajo_responsable}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}