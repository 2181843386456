import axios from 'axios';

export default class WorkOrderSaleService {
    
    getOrdenTrabajoVentas(ordentrabajo) {		
        const url = process.env.VUE_APP_APIURL + `ordentrabajoventas/ordentrabajos/${ordentrabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenTrabajoVenta(ordentrabajoventa) {
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoventas/${ordentrabajoventa.id_orden_trabajo_venta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenTrabajoVenta(ordentrabajoventa){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoventas/${ordentrabajoventa.id_orden_trabajo_venta}`;
		return axios.put(url,ordentrabajoventa).then(res=>res.data.data);
	}

	createOrdenTrabajoVenta(ordentrabajoventa){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoventas`;
		return axios.post(url,ordentrabajoventa).then(res=>res.data.data);
	}

	deleteOrdenTrabajoVenta(ordentrabajoventa){
        const url = process.env.VUE_APP_APIURL + `/ordentrabajoventas/${ordentrabajoventa.id_orden_trabajo_venta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}